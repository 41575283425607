.address {
    color: #ffffff;
    opacity: 0.5;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    margin-top: 20px !important;
    margin-bottom: 5px;
}

.academyDiv {
    color: #ffffff;
    opacity: 0.5;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin-top: 20px;
    margin-bottom: 5px;
}

.addressText {
    font-weight: 500;
    font-size: 14px !important;
    line-height: 19px !important;
    letter-spacing: 0.02em !important;
    color: #ffffff;
    opacity: 0.8;
}

.divider {
    margin-left: 50px !important;
    margin-right: 58px !important;
    background-color: #ffffff !important;
    height: 0.1px !important;
    margin-top: 20px !important;
}

.copyright {
    margin-left: 50px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #ffffff;
    opacity: 0.8;
}

.timingsDiv {
    display: flex;
}

.academyTiming {
    font-weight: 500;
    font-size: 14px !important;
    line-height: 19px !important;
    letter-spacing: 0.02em !important;
    color: #ffffff;
    opacity: 0.8;
    margin-left: -33px !important;
}

/* //mobile screen
// @media only screen and (max-width: 768px) {
//     .address{
//         left:15px;
//         position:absolute;
//     }
// } */
