.flexRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.flexColumn {
    display: flex;
    flex-direction: column;
}

.centerDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.textCenter {
    text-align: center;
}
.flexEnd {
    text-align: end;
    justify-content: flex-end;
}

.hair1 {
    background-size: cover;
}
.hair2 {
    background-size: cover;
}

.stretch {
    width: 100%;
    height: 100%;
}
.subTitle {
    font-family: Futura;
    font-weight: bold;
    letter-spacing: 0.02em;
    color: #ffffff;
    opacity: 0.8;
}
.subTitleDark {
    font-family: Futura;
    font-weight: bold;
    letter-spacing: 0.02em;
    color: #000000;
    opacity: 0.6;
}
.text12 {
    font-size: 12px;
    line-height: 19px;
    font-family: Futura;
    line-height: 16px;
    /* font-style: italic; */
    font-weight: 500;
}
.text14 {
    font-family: Futura;
    font-style: normal;
    font-size: 14px;
    line-height: 19px;
    width: 80%;
}
.text18 {
    font-family: Futura;
    font-style: normal;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
}
.text13 {
    font-family: Futura;
    font-style: normal;
    font-size: 13px;
    line-height: 19px;
    width: 80%;
}
.text16 {
    font-family: Futura;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}
.text24 {
    font-family: Futura;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
}
.text36 {
    font-family: Futura;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
}
.text48 {
    font-family: Futura;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 64px;
    letter-spacing: 0.01em;
}
.white {
    color: #ffffff;
}
.subContent {
    position: absolute;
    left: 100px;
    padding: 10px;
    font-family: Futura;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 20px;
    letter-spacing: 0.02em;
}

.btn-outline-dark {
    font-family: Futura;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 14px;
    line-height: 19px;
    height: 50px;
    width: 241px;
    color: #000000 !important;
}

.dot-btn {
    border-radius: 50%;
    height: 11px;
    width: 11px;
    padding: 0;
    margin-right: 4px;
}

.dot-light {
    background-color: #ffffff;
    opacity: 0.23;
}
.dot-light--active {
    background-color: #ffffff;
}

.dot-dark {
    background-color: #000;
    opacity: 0.5;
}
.dot-dark--active {
    background-color: #000;
}

.m20 {
    margin: 20px 0;
}
.mentorCard {
    margin: 10px;
    width: '90%';
    height: 360px;
    color: #ffffff;
    font-size: 18px;
    background: #1b1b1b;
}
.mentorCardTitle {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.02em;
}
.cardImg {
    width: 400px;
    height: 200px;
}
.overlap {
    margin-top: -40px;
    margin-bottom: 20px;
}
.innerPadding {
    padding: 0 20px;
}
.smallIcon {
    font-size: 10px;
    color: #ffffff;
    margin-right: 5px;
}
